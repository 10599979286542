import { SnotifyService } from 'ng-snotify';
import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder, NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { JarwisService } from '../Services/jarwis.service';
export interface User {
  id: string;
  name: string;
  user_type: string;
}
@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})



export class CreateEventComponent implements OnInit {
  @ViewChild('myForm') myForm: NgForm;
  filteredUsers: Observable<User[]>;
  minDate = new Date();
  users: User[] = [];
  categories = [
    {value: '0', viewValue: 'EMERGENCY'},
    {value: '1', viewValue: 'UNIVERSITY'},
    {value: '2', viewValue: 'PUBLIC MEETINGS'},
    {value: '3', viewValue: 'ROUTINE'}
  ];
  public error: string = null;
  options: string[] = ['One', 'Two', 'Three', 'Four'];
  filteredOptions: Observable<string[]>;
  phone = false;
  dueDate = true;
  createEventForm: FormGroup;
  public controlPointer = {
    subject: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    dueDate: new FormControl({value: '', disabled: true}, [Validators.required]),
    dueTime: new FormControl('', [Validators.required]),
    assignedTo: new FormControl('', [Validators.required]),
    assignedToPhone: new FormControl('', [])
  };

  public controlPointerValues = {
    subject: null,
    category: null,
    dueDate: null,
    dueTime: null,
    assignedTo: null,
    assignedToPhone: null
  };

  onSubmit() {

    // console.log(this.controlPointerValues.assignedTo);
    if (this.createEventForm.invalid) {
        return;
    }
    this.Notify.info('Wait...', {timeout: 5000});
    this.controlPointerValues.subject = this.controlPointer.subject.value;
    this.controlPointerValues.category = this.controlPointer.category.value;
    if (this.dueDate) {
      this.controlPointerValues.dueDate = this.controlPointer.dueDate.value;
      this.controlPointerValues.dueTime = this.controlPointer.dueTime.value;
    } else {
      this.controlPointerValues.dueDate = 'N/A';
      this.controlPointerValues.dueTime = 'N/A';
    }
    if (this.phone) {
      this.controlPointerValues.assignedTo = this.controlPointer.assignedTo.value;
      this.controlPointerValues.assignedToPhone = this.controlPointer.assignedToPhone.value;
    } else {
      this.controlPointerValues.assignedTo = this.controlPointer.assignedTo.value.id;
    }
    this.Jarwis.createEvent(this.controlPointerValues).subscribe(
      data => this.handleDataForm(data),
      error => this.handleErrorForm(error)
    );
  }
  handleDataForm(data) {
    this.error = '';
    this.Notify.clear();
    this.Notify.success('Event Created Successfully');
    this.createEventForm.reset();
    this.myForm.resetForm();
  }
  handleErrorForm(error) {
    this.Notify.clear();
    this.Notify.error('Unable to Create Event');
    // console.log(error);
    this.error = 'Unable to Create Event';
  }
  checkAssignedTo() {
    const assignedTo = this.controlPointer.assignedTo.value;
    const filteredResult = this.users.filter(option => option.name.indexOf(assignedTo.name) === 0);
    if (filteredResult.length > 0) {
      this.phone = false;
      this.controlPointer.assignedToPhone.setValidators([]);
      this.controlPointer.assignedToPhone.updateValueAndValidity();

    } else {
      this.phone = true;
      this.controlPointer.assignedToPhone.setValidators([Validators.required, Validators.pattern('\\+92[0-9]{10}')]);
      this.controlPointer.assignedToPhone.updateValueAndValidity();
    }
  }

  constructor(
    private Jarwis: JarwisService,
    private fb: FormBuilder,
    private Notify: SnotifyService
    ) { }

  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.createEventForm = this.fb.group(this.controlPointer);
    this.controlPointer.assignedTo.valueChanges.subscribe(val => {
      this.checkAssignedTo();
    });
    this.Jarwis.getAllUserNames().subscribe(
      data => this.handleUsers(data),
      error => console.log(error)
    );
    this.controlPointer.category.valueChanges.subscribe(val => {
      if (val === '3') {
        this.dueDate = false;
        this.controlPointer.dueDate.setValidators([]);
        this.controlPointer.dueDate.updateValueAndValidity();
        this.controlPointer.dueTime.setValidators([]);
        this.controlPointer.dueTime.updateValueAndValidity();
      } else {
        this.dueDate = true;
        this.controlPointer.dueDate.setValidators([Validators.required]);
        this.controlPointer.dueDate.updateValueAndValidity();
        this.controlPointer.dueTime.setValidators([Validators.required]);
        this.controlPointer.dueTime.updateValueAndValidity();
      }
    });
    this.filteredUsers = this.controlPointer.assignedTo.valueChanges
      .pipe(
        startWith(''),
        // map(state => state ? this._filter(state) : this.users.slice())
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.users.slice())
      );
  }
  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(user?: User): string | undefined {
    return user ? user.name + ' | ' + user.user_type : undefined;
  }


  handleUsers(data) {
    this.users = data;
    // console.log(this.users);
  }


  getSubjectError() {
    return this.controlPointer.subject.hasError('required') ? 'You must enter the Subject' : '';
  }

  getCategoryError() {
    return this.controlPointer.category.hasError('required') ? 'You must enter the Category' : '';
  }

  getDueDateError() {
    return this.controlPointer.dueDate.hasError('required') ? 'You must enter the Date' : '';
  }

  getDueTimeError() {
    return this.controlPointer.dueTime.hasError('required') ? 'You must enter the Time' : '';
  }

  getAssignedToError() {
    return this.controlPointer.assignedTo.hasError('required') ? 'You must assign the Task' :
    this.controlPointer.assignedTo.hasError('wrongOption') ? 'You must select option from the list' : '';
  }
  getPhoneNumberError() {

    return this.controlPointer.assignedToPhone.hasError('required') ? 'You must enter your Phone Number' :
    this.controlPointer.assignedToPhone.hasError('pattern') ? 'You must enter valid Phone Number (+923XXXXXXXXX)' : '';
  }
}
