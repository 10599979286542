import { SnotifyService } from 'ng-snotify';
import { JarwisService } from './../Services/jarwis.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface User {
  id: string;
  name: string;
}
@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})

export class CreateUsersComponent implements OnInit{

  filteredOptions: Observable<User[]>;
  createUsersForm: FormGroup;
  public controlPointer = {
    email: new FormControl('',[Validators.email,Validators.required]),
    password: new FormControl('',[Validators.required,Validators.minLength(6)]),

    confirmPassword: new FormControl('', [Validators.required]),
    name: new FormControl('',[Validators.required]),
    phoneNumber: new FormControl('',[Validators.required, Validators.pattern('\\+92[0-9]{10}')]),
    userType: new FormControl('',[Validators.required])
  };
  public error = null;
  public controlPointerValues = {
    email: null,
    password: '',
    name: null,
    phoneNumber: null,
    userType: null
  };

  categories = [
    {value: '0', viewValue: 'Secretary'},
    {value: '1', viewValue: 'Special Secretary'},
    {value: '2', viewValue: 'Additional Secretary'},
    {value: '3', viewValue: 'Deputy Secretary '}
  ];

  constructor(
    private fb: FormBuilder,
    private Jarwis: JarwisService,
    private Notify: SnotifyService
    ) { }

  ngOnInit() {

    this.createUsersForm = this.fb.group(this.controlPointer);
    this.createUsersForm.get('confirmPassword').valueChanges.subscribe(val => {
     if(this.createUsersForm.get('password').value != this.createUsersForm.get('confirmPassword').value){
      this.createUsersForm.get('confirmPassword').setErrors({'notSame': true});
     }else {
      if (this.createUsersForm.get('confirmPassword').hasError('notSame')) {
        delete this.createUsersForm.get('confirmPassword').errors['notSame'];
        this.createUsersForm.get('confirmPassword').updateValueAndValidity();
      }
      this.createUsersForm.updateValueAndValidity();
     }
    });
    this.createUsersForm.get('password').valueChanges.subscribe(val => {
      if(this.createUsersForm.get('password').value != this.createUsersForm.get('confirmPassword').value){
       this.createUsersForm.get('confirmPassword').setErrors({'notSame': true});
      } else {
       if (this.createUsersForm.get('confirmPassword').hasError('notSame')) {
         delete this.createUsersForm.get('confirmPassword').errors['notSame'];
         this.createUsersForm.get('confirmPassword').updateValueAndValidity();
       }
       this.createUsersForm.updateValueAndValidity();
      }
     });

  }


  checkEmail() {
    this.controlPointerValues.email = this.controlPointer.email.value;
    this.Jarwis.checkEmail(this.controlPointerValues).subscribe(
      data => this.handleEmailData(data),
      error => this.handleEmailError(error)
    );
  }
  handleEmailData(data) {

    if (this.createUsersForm.get('email').hasError('emailExists')) {
      delete this.createUsersForm.get('email').errors['emailExists'];
      this.createUsersForm.get('email').updateValueAndValidity();
      this.createUsersForm.updateValueAndValidity();
    }
  }
  handleEmailError(error) {
    this.Notify.clear();
    this.Notify.error('An Account with the same email already exists');
    // console.log(error);
    this.createUsersForm.get('email').setErrors({'emailExists': true});
  }
  getEmailError() {
    return this.controlPointer.email.hasError('required') ? 'You must enter your Email Address' :
        this.controlPointer.email.hasError('email') ? 'You must enter Valid Email Address' :
        this.controlPointer.email.hasError('emailExists') ? 'Email Already Exists' : '';
  }
  getPasswordError() {
    return this.controlPointer.password.hasError('required') ? 'You must enter your Password' :
    this.controlPointer.password.hasError('minlength') ? 'Your password length must be greater than 6' : '';
  }

  getConfirmPasswordError() {
    return this.controlPointer.confirmPassword.hasError('required') ? 'You must enter your Confirm Password' :
    this.controlPointer.confirmPassword.hasError('notSame') ? 'Your passwords does not match' : '';
  }

  getNameError() {
    return this.controlPointer.name.hasError('required') ? 'You must enter your Name' : '';
  }

  getPhoneNumberError() {
    return this.controlPointer.phoneNumber.hasError('required') ? 'You must enter your Phone Number' :
    this.controlPointer.phoneNumber.hasError('pattern') ? 'You must enter valid Phone Number (+923XXXXXXXXX)' : '';
  }

  getUserTypeError() {
    return this.controlPointer.userType.hasError('required') ? 'You must select User Type' : '';
  }
  onSubmit() {

    if (this.createUsersForm.invalid) {
        return;
    }
    this.Notify.clear();
    this.Notify.info('Wait...', {timeout: 5000});
    this.controlPointerValues.name = this.controlPointer.name.value;
    this.controlPointerValues.email = this.controlPointer.email.value;
    this.controlPointerValues.password = this.controlPointer.password.value;
    this.controlPointerValues.phoneNumber = this.controlPointer.phoneNumber.value;
    this.controlPointerValues.userType = this.controlPointer.userType.value;
    this.Jarwis.createUser(this.controlPointerValues).subscribe(
      data => this.handleData(data),
      error => this.handleError(error)
    );
  }
  handleError(error) {
    this.Notify.clear();
    this.Notify.error('Unable to Register User');
    console.log(error);
    this.error = 'Unable to Register User';
  }
  handleData(data) {
    this.Notify.clear();
    this.Notify.success('User Registered Successfully');
    this.createUsersForm.reset();
  }
}
