import { UserDetailsComponent } from './user-details/user-details.component';
import { UsersComponent } from './users/users.component';
import { AfterLoginService } from './Services/after-login.service';
import { TasksComponent } from './tasks/tasks.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { CreateUsersComponent } from './create-users/create-users.component';
import { TaskDetailsComponent } from './task-details/task-details.component';
import { BeforeLoginService } from './Services/before-login.service';

const routes: Routes = [
  {path: '', component: LoginComponent, canActivate: [BeforeLoginService]},
  {
    path: 'admin',
    component: NavigationComponent,
    canActivate: [AfterLoginService],

    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'create-event', component: CreateEventComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'notifications', component: NotificationsComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'create-users', component: CreateUsersComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'tasks', component: TasksComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'users', component: UsersComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'task-details/:id', component: TaskDetailsComponent,
        canActivate: [AfterLoginService],
      },
      {
        path: 'user-details/:id', component: UserDetailsComponent,
        canActivate: [AfterLoginService],
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
