import {
  JarwisService
} from './../Services/jarwis.service';
import {
  UserService,
  LoggedInUser
} from './../Services/user.service';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  TokenService
} from '../Services/token.service';
import {
  AuthService
} from '../Services/auth.service';
import Pusher from 'pusher-js';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public loggedIn: Boolean;
  public LoggedUser :LoggedInUser;
  public notification: string;
  constructor(
    private Auth: AuthService,
    private router: Router,
    private User: UserService,
    private Token: TokenService,
    private Jarwis: JarwisService,
    public Notify: SnotifyService
  ) {}
  private pusherClient: Pusher;
  handleNotifications(data){
    console.log(data);
    this.Notify.success(this.notification);

  }
  subscribe() {
    this.pusherClient = new Pusher('f20b69d29bd7f4b1779e', {
      cluster: 'ap2'
    });

    const channel = this.pusherClient.subscribe('notification-' + this.LoggedUser.id);

    channel.bind(
      'notification',
      data => {
        this.notification = data.message;
        this.handleNotifications(data.message);

      });
}

ngOnInit() {

  this.LoggedUser = {
    id: null,
    name: null,
    email: null,
    phoneNo: null,
    user_type: null
  };
  // this.User.refresh();
  this.Jarwis.getLoggedInUser().subscribe(data => {
    this.User.handle(data as LoggedInUser);
    // console.log(this.User.get());

    this.LoggedUser = this.User.get();
    this.subscribe();
  });

  this.Auth.authStatus.subscribe(value => this.loggedIn = value);

}


logout($event) {

  $event.preventDefault();
  this.Token.remove();
  this.Auth.changeAuthStatus(false);
  this.router.navigateByUrl('/');
}

}
