import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { JarwisService } from '../Services/jarwis.service';
import { Router } from '@angular/router';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {

  constructor(private cdRef: ChangeDetectorRef, private Jarwis: JarwisService, private router: Router) { }
  @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective) mdbTable1: MdbTableDirective;
  public elements: any = [];
  searchText = '';
  previous: any = [];
  prevIndex = 0;
  headElements = ['Name', 'Designation', 'Email', 'Phone No.', 'Average Ratings', 'Edit'];
  public trimming_fn(x) {
    return x ? x.replace(/\s/g, '') : '';
  }
  @HostListener('input') oninput() {
    this.searchItems();
  }
  searchItems() {
    const prev = this.mdbTable1.getDataSource();

    if (!this.searchText) {
      this.mdbTable1.setDataSource(this.previous);
      this.elements = this.mdbTable1.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable1.searchLocalDataBy(this.searchText);
      this.mdbTable1.setDataSource(prev);
    }
  }
  ngOnInit() {
    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();

  }
  ngAfterViewInit() {
    this.Jarwis.getAllUsers().subscribe(
      data => this.handleData(data),
      error => console.log(error)
    );
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  userDetails(id) {
    this.Jarwis.userId = id;
    this.router.navigateByUrl('/admin/user-details/' + id);

  }
  handleData(data) {
    this.elements = data;
    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();

  }

}
