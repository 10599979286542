import { Component, OnInit, AfterViewInit } from '@angular/core';
import { JarwisService } from '../Services/jarwis.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, AfterViewInit {
  public elements: any = []

  constructor(private Jarwis: JarwisService, private router: Router) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    this.Jarwis.getAllNotifications().subscribe(
      data => this.handleData(data),
      error => console.log(error)
    );
  }
  taskDetails(id) {
    this.router.navigateByUrl('/admin/task-details/' + id);
  }
  handleData(data) {
    this.elements = data;
  }
}
