import { JarwisService } from './jarwis.service';
import { Injectable } from '@angular/core';
export class LoggedInUser {
  id: string;
  name: string;
  email: string;
  phoneNo: string;
  user_type: string;

}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userType = [
    { name : 'Secretary'},
    { name : 'Special Secretary'},
    { name : 'Assistant Secretary'},
    { name : 'Deputy Secretary'},
  ];
  private loggedUser: LoggedInUser;
  constructor(private Jarwis: JarwisService) { }
  handle(user: LoggedInUser){

    this.setUserType(user.user_type, user);

  }
  setUserType(userType, user: LoggedInUser){
    user.user_type = this.userType[userType].name;
    this.set(user);
  }
  set(user: LoggedInUser) {
    this.loggedUser = user;
    // localStorage.setItem('userId', user.id);
  }

  refresh(){
    // if(!this.loggedUser){
      return this.Jarwis.getLoggedInUser().subscribe( data => {
        this.handle(data as LoggedInUser);
        return this.loggedUser;
      });
    // }

    // return f;
  }
  get(){
    return this.loggedUser;
  }
}
