import {
  ActivatedRoute
} from '@angular/router';
import {
  JarwisService
} from './../Services/jarwis.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject
} from '@angular/core';
import {
  Observable
} from 'rxjs';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import {
  SnotifyService
} from 'ng-snotify';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material';
import * as moment from 'moment/moment';
import { startWith, map } from 'rxjs/operators';
import { LoggedInUser, UserService } from '../Services/user.service';
@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit, AfterViewInit {

  commentFormGroup: FormGroup;
  private state: Observable < object > ;
  public loggedUser: LoggedInUser;
  public formValues = {
    comment: null,
    task_id: null
  };
  public options;
  public form = {
    comment: new FormControl('', [Validators.required]),
    ratings: new FormControl('', [])
  };


  public task = {
    id: null,
    due_date_time: null,
    dueTime: null,
    dueDate: null,
    days: null,
    priority: null,
    task_completed: null,
    task_completed_on: null,
    task_completed_by: null,
    created_by: null,
    created_by_id: null,
    task_completed_by_id: null,
    subject: null,
    category: null,
    assigns: null,
    comments: null,
    feedbackComment: null,
    ratings: null

  };

  constructor(
    private Jarwis: JarwisService,
    public activatedRoute: ActivatedRoute,
    private Notify: SnotifyService,
    private fb: FormBuilder,
    private UserServicess: UserService,
    public dialog: MatDialog
  ) {
    this.activatedRoute.params.subscribe(params => this.handleParam(params));
    this.loggedUser = {
      id: null,
      name: null,
      email: null,
      phoneNo: null,
      user_type: null
    };
    // this.User.refresh();
    this.Jarwis.getLoggedInUser().subscribe(data => {
      this.UserServicess.handle(data as LoggedInUser);
      // console.log(this.User.get());

      this.loggedUser = this.UserServicess.get();
      // this.subscribe();
    });

  }
  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.commentFormGroup = this.fb.group(this.form);
  }
  handleParam(data) {
    this.formValues.task_id = data.id;
  }
  onSubmit() {
    if (this.commentFormGroup.invalid) {
      return;
    }
    this.Notify.info('Wait...', {
      timeout: 5000
    });
    this.formValues.comment = this.form.comment.value;
    this.Jarwis.addComment(this.formValues).subscribe(
      data => this.handleCommentData(data),
      error => this.handleError(error)
    );
  }
  handleError(error) {
    this.Notify.clear();
    this.Notify.error('Unable to add comment');
  }
  openDateDialog(): void {
    const dialogRef = this.dialog.open(modalDialog, {
      width: '300px',
      data: {
        event_id: this.task.id,
        type: 0,
        date: new Date(this.task.due_date_time),
        time: this.task.dueTime
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        if (!
          (moment(result.date).format('YYYY-MM-DD') === moment(new Date(this.task.due_date_time)).format('YYYY-MM-DD')
          && result.time === this.task.dueTime)) {
            this.Jarwis.updateDueDate(result).subscribe(
              data => this.handleDueDateDate(data),
              error => console.log(error)
            );



        }
      }

    });
  }
  openReassignDialog(): void {


    const dialogRef = this.dialog.open(modalDialog, {
      width: '300px',
      data: {
        event_id: this.task.id,
        type: 1,
        date: new Date(this.task.due_date_time),
        time: this.task.dueTime
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const assigned = {
          name: null
        };
        assigned.name = result.name;
        this.task.assigns.push(assigned);
      }

    });
  }
  giveFeedback(): void {
    if (this.loggedUser.name !== this.task.created_by) {
      this.Notify.clear();
      this.Notify.error('You are not authorized to give feedback');
    } else {
      const dialogRef = this.dialog.open(modalDialog, {
        width: '300px',
        data: {
          event_id: this.task.id,
          type: 2,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result.ratings);
          this.task.ratings = result.ratings;
          this.task.feedbackComment = result.comments;
        }

      });
    }


  }
  handleDueDateDate(data) {
    this.Notify.clear();
    this.Notify.success('Due Date and Time Changed Successfully');
    this.task.dueDate = data.dueDate;
    this.task.dueTime = data.dueTime;
    this.task.days = data.days;
    this.task.priority = data.priority;
  }
  public trimming_fn(x) {
    return x ? x.toString().replace(/\s/g, '') : '';
  }
  completeTask() {
    // console.log(this.task.task_completed);
    if (this.task.task_completed !== 1) {
      this.Notify.clear();
      this.Notify.success('Task Completed Successfully');
      const formData = {event_id : this.task.id};
      this.Jarwis.completeTask(formData).subscribe(
        data => this.handleTaskComplete(data),
        error => console.log(error)
      );
    } else {
      this.Notify.clear();
      this.Notify.error('Task is Already Completed');
    }

  }
  handleTaskComplete(data) {
    this.task.task_completed = 1;
    this.task.priority = 'Completed';
    this.task.days = 'Completed';
    this.task.task_completed_on = data.task_completed_on;
    this.task.task_completed_by = data.task_completed_by;
  }
  handleCommentData(data) {
    this.Notify.clear();
    this.Notify.success('Comment Added Successfully');
    this.task.comments.push({
      name: data.user,
      comment: this.formValues.comment
    });
    this.commentFormGroup.reset();
  }
  handleData(data) {
    this.task = data.task;
    this.task.feedbackComment = data.feedback.comment;
    this.task.ratings = data.feedback.ratings;
    // console.log(this.task);
    // console.log(data.feedback);
    this.task.task_completed = JSON.parse(data.task.task_completed);
    // this.form.ratings.value = this.task.ratings;

  }
  ngAfterViewInit() {
    this.Jarwis.getTask(this.formValues).subscribe(
      data => this.handleData(data),
      error => console.log(error)
    );
  }

}
export interface User {
  id: string;
  name: string;
}
export interface DialogData   {
  type: number;
  date: Date;
  time: string;
  comment: string;
  ratings: string;
  event_id: string;
  user_id: string;

}

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: './modals/dialog-date-modal.html',
})
export class modalDialog {
  minDate = new Date();
  public error: string = null;
  filteredUsers: Observable<User[]>;
  createEventForm: FormGroup;
  users: User[] = [];
  public controlPointer = {
    assignedTo: new FormControl('', [Validators.required]),
    ratings: new FormControl('', []),
    comment: new FormControl('', []),
  };

  public controlPointerValues = {
    eventId: null,
    assignedTo: null,
    comment: null,
    ratings: null,
  };

  constructor(
    public dialogRef: MatDialogRef < modalDialog > ,
    private Jarwis: JarwisService,
    private fb: FormBuilder,

    private Notify: SnotifyService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.controlPointerValues.eventId = data.event_id;
      this.createEventForm = this.fb.group(this.controlPointer);
      this.Jarwis.getAllUserNames().subscribe(
        data => this.handleUsers(data),
        error => console.log(error)
      );

      this.filteredUsers = this.controlPointer.assignedTo.valueChanges
        .pipe(
          startWith(''),
          // map(state => state ? this._filter(state) : this.users.slice())
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.users.slice())
        );
    }

  onNoClick(): void {
    event.preventDefault();
    this.dialogRef.close();
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();

    return this.users.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(user?: User): string | undefined {
    return user ? user.name : undefined;
  }
  onSubmit() {
    if (this.createEventForm.invalid) {
        return;
    }
    this.Notify.info('Wait...', {timeout: 5000});
    this.controlPointerValues.assignedTo = this.controlPointer.assignedTo.value.id;
    this.Jarwis.reassignEvent(this.controlPointerValues).subscribe(
      data => this.handleDataForm(data),
      error => this.handleErrorForm(error)
    );
  }
  handleDataForm(data) {
    this.Notify.clear();
    this.Notify.success('Event Reassigned Successfully');
    this.dialogRef.close(this.controlPointer.assignedTo.value);
    this.createEventForm.reset();

  }
  handleErrorForm(error) {
    this.Notify.clear();
    this.Notify.error('Unable to Reassign Event');
    // console.log(error);
    this.error = 'Unable to Reassign Event';
  }
  onSubmit1() {

    this.Notify.info('Wait...', {timeout: 5000});

    this.controlPointerValues.ratings = this.controlPointer.ratings.value;
    this.controlPointerValues.comment = this.controlPointer.comment.value;

    this.Jarwis.submitFeedback(this.controlPointerValues).subscribe(
      data => this.handleDataForm1(data),
      error => this.handleErrorForm1(error)
    );
  }
  handleDataForm1(data) {
    this.Notify.clear();
    this.Notify.success('Feedback Submitted Successfully');
    const rat = {
      comments: this.controlPointer.comment.value,
      ratings: this.controlPointer.ratings.value
    };
    this.dialogRef.close(rat);
    this.createEventForm.reset();

  }
  handleErrorForm1(error) {
    this.Notify.clear();
    this.Notify.error('Unable to Submit Feedback');
    // console.log(error);
    this.error = 'Unable to Submit Feedback';
  }

  checkAssignedTo() {
    const assignedTo = this.controlPointer.assignedTo.value;
    const filteredResult = this.users.filter(option => option.name.indexOf(assignedTo.name) === 0);
    if (filteredResult.length > 0) {
      if (this.createEventForm.get('assignedTo').hasError('wrongOption')) {
        delete this.createEventForm.get('assignedTo').errors.wrongOption;
        this.createEventForm.get('assignedTo').updateValueAndValidity();
        this.createEventForm.updateValueAndValidity();
      }

    } else {
      this.createEventForm.get('assignedTo').setErrors({wrongOption: true});
    }
  }
  handleUsers(data) {
    this.users = data;
  }
  getAssignedToError() {
    return this.controlPointer.assignedTo.hasError('required') ? 'You must assign the Task' :
    this.controlPointer.assignedTo.hasError('wrongOption') ? 'You must select option from the list' : '';
  }
}
