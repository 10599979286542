import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { JarwisService } from '../Services/jarwis.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../Services/user.service';
import { MatDialog } from '@angular/material';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})

export class UserDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective) mdbTable1: MdbTableDirective;
  public elements: any = [];
  searchText = '';
  previous: any = [];
  prevIndex = 0;
  headElements = ['Task Name', 'Feedback Comment', 'Ratings', 'Rated By', 'Edit'];
  public user = {
    id: null,
    name: null,
    email: null,
    phoneNumber: null,
    designation: null,
    ratings: 0,
  };

  public formValues = {
    user_id: null
  };
  constructor(
    private cdRef: ChangeDetectorRef,
    private Jarwis: JarwisService,
    public activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router

  ) {
    this.activatedRoute.params.subscribe(params => this.handleParam(params));

  }
  handleParam(data) {
    this.formValues.user_id = data.id;
  }
  public trimming_fn(x) {
    return x ? x.replace(/\s/g, '') : '';
  }
  @HostListener('input') oninput() {
    this.searchItems();
  }
  searchItems() {
    const prev = this.mdbTable1.getDataSource();

    if (!this.searchText) {
      this.mdbTable1.setDataSource(this.previous);
      this.elements = this.mdbTable1.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable1.searchLocalDataBy(this.searchText);
      this.mdbTable1.setDataSource(prev);
    }
  }
  ngOnInit() {
    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();

  }
  handleData1(data) {
    this.user = data.user;
    this.elements = data.tasks;
    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();

  }
  ngAfterViewInit() {
    this.Jarwis.getUser(this.formValues).subscribe(
      data => this.handleData1(data),
      error => console.log(error)
    );

    this.mdbTablePagination.setMaxVisibleItemsNumberTo(20);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  taskDetails(id) {
    this.Jarwis.taskId = id;
    this.router.navigateByUrl('/admin/task-details/' + id);

  }


}
