import { UserService } from './../Services/user.service';
import { TokenService } from './../Services/token.service';
import { JarwisService } from './../Services/jarwis.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../Services/auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginFormGroup: FormGroup;
  loggedIn: boolean;

  public form = {
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  };
  public formValues = {
    email: null,
    password: null
  };
  public error = null;
  constructor(
    private fb: FormBuilder,
    private Jarwis: JarwisService,
    private Token: TokenService,
    private router: Router,
    private Auth: AuthService,
    private Notify: SnotifyService,
    private User: UserService
    ) { }

  ngOnInit() {
    this.createForm();
    this.Auth.authStatus.subscribe(value => this.loggedIn = value);

  }
  createForm(){
    this.loginFormGroup = this.fb.group(this.form);
  }
  onSubmit() {
    if (this.loginFormGroup.invalid) {
        return;
    }
    this.Notify.info('Wait...', {timeout: 5000});
    this.formValues.email = this.form.email.value;
    this.formValues.password = this.form.password.value;
    this.Jarwis.login(this.formValues).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  handleResponse(data){

    this.Notify.clear();
    this.Notify.success('Welcome Back, You are logged in successfully');
    this.Token.handle(data.token);
    this.Auth.changeAuthStatus(true);
    this.User.handle(data.user);
    console.log(data);
    this.router.navigateByUrl('/admin');
  }
  handleError(error){
    this.Notify.clear();
    this.Notify.error(error.error.error);
    this.error = error.error.error;
  }
  getEmailError(){
    return this.form.email.hasError('required') ? 'You must enter your Email Address' :
        this.form.email.hasError('email') ? 'You must enter Valid Email Address' : '';
  }
  getPasswordError(){
    return this.form.password.hasError('required') ? 'You must enter your Password' : '';
  }

}
