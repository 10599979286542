import {
  NgModule
} from '@angular/core';
import {
  CommonModule
} from '@angular/common';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  MatButtonModule,
  MatNativeDateModule,
  MatIconModule,
  MatSidenavModule,
  MatListModule,
  MatToolbarModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatBadgeModule,
  MatTableModule,
  MatTabsModule,
  MatDialogModule
} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTableModule,
    MatBadgeModule,
    MatDialogModule

  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatTableModule,
    MatTabsModule,
    NgxMaterialTimepickerModule,
    MatDialogModule
    // NgxMatSelectSearchModule,

  ],
})
export class CustomMaterialModule {}
