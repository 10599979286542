import { JarwisService } from './../Services/jarwis.service';
import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTablePaginationComponent) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective) mdbTable1: MdbTableDirective;
  public elements: any = [];
  searchText = '';
  previous1: string;
  previous: any = [];
  prevIndex = 0;
  headElements = ['Events', 'Due Date', 'Due Time', 'Assigned To', 'Category', 'Completed On', 'Completed By', 'Priority', 'Edit'];

  constructor(private cdRef: ChangeDetectorRef, private Jarwis: JarwisService, private router: Router) { }


  public chartType = 'doughnut';
  categories: any = [
    {category: 'Emergency', counter: 0},
    {category: 'Public Meetings', counter: 0},
    {category: 'University', counter: 0},
    {category: 'Routine', counter: 0},
  ];
  categories1: any = [

    {category: 'Completed Tasks', counter: 0},
    {category: 'Missed Tasks', counter: 0},
    {category: 'Late Submissions', counter: 0},
    {category: 'Top Priority', counter: 0},
    {category: 'Normal Priority', counter: 0},
    {category: 'Routine Priority', counter: 0},
  ];
  public categoryDatasets: Array<any> = [
    { data: [100, 50, 100], label: 'Categories' }
  ];

  public categoryLabels: Array<any> = ['Emergency', 'Public Meetings', 'University', 'Routine'];

  public categoryColors: Array<any> = [
    {
      backgroundColor: ['#F40000', '#ff9d00', '#0000FB', '#800080'],
      hoverBackgroundColor: ['#F40000', '#ff9d00', '#0000FB', '#800080'],
      borderWidth: 2,
    }
  ];

  public priorityDatasets: Array<any> = [
    {data: [10, 50, 13, 20, 10], label: 'Priorities'}
  ];

  public priorityLabels: Array<any> = [
    'Missed Tasks', 'Top Priority', 'Normal Priority', 'Routine Priority', 'Completed Tasks', 'Late Submissions'];

  public priorityColors: Array<any> = [
    {
      backgroundColor: ['#000000', '#F40000', 'green', '#0000FB', '#A6D608'],
      hoverBackgroundColor: ['#000000', '#F40000', 'green', '#0000FB', '#A6D608'],
      borderWidth: 2,
    }
  ];
  public chartOptions: any = {
    responsive: true
  };

  displayedColumns = ['category', 'counter'];
  public trimming_fn(x) {
    return x ? x.replace(/\s/g, '') : '';
  }
  public chartClicked(e: any): void {
    if(this.prevIndex === e.active[0]._index){
      this.prevIndex = 9;
      this.searchText = '';
    } else {
      this.prevIndex = e.active[0]._index;
      if (e.active[0]._index === 0) {
        this.searchText = 'Emergency';
      } else if(e.active[0]._index === 1) {
        this.searchText = 'Public Meetings';
      } else if(e.active[0]._index === 2) {
        this.searchText = 'University';
      } else if(e.active[0]._index === 3) {
        this.searchText = 'Routine';
      }
    }
    this.searchItems();
  }
  public chartClicked1(e: any): void {
    if (this.prevIndex === e.active[0]._index){
      this.prevIndex = 9;
      this.searchText = '';
    } else {
      this.prevIndex = e.active[0]._index;
      if (e.active[0]._index === 0) {
        this.searchText = 'Missed';
      } else if (e.active[0]._index === 1) {
        this.searchText = 'Top Priority';
      } else if (e.active[0]._index === 2) {
        this.searchText = 'Normal Priority';
      } else if (e.active[0]._index === 3) {
        this.searchText = 'Routine Priority';
      } else if (e.active[0]._index === 4) {
        this.searchText = 'Completed';
      } else if(e.active[0]._index === 5) {
        this.searchText = 'Late Submission';
      }
    }
    this.searchItems();
  }
  public chartHovered(e: any): void {

  }


  @HostListener('input') oninput() {
    this.searchItems();
  }
  searchItems() {
    const prev = this.mdbTable1.getDataSource();

    if (!this.searchText) {
      this.mdbTable1.setDataSource(this.previous);
      this.elements = this.mdbTable1.getDataSource();
    }

    if (this.searchText) {
      this.elements = this.mdbTable1.searchLocalDataBy(this.searchText);
      this.mdbTable1.setDataSource(prev);
    }
  }
  ngOnInit() {

    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();
  }

  ngAfterViewInit() {
    this.Jarwis.fillDashboard().subscribe(
      data => this.handleData(data),
      error => console.log(error)
    );
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  taskDetails(id) {
    this.Jarwis.taskId = id;
    this.router.navigateByUrl('/admin/task-details/' + id);

  }
  handleData(data){

    this.categories.forEach(element => {
      element.counter = data.counts1[element.category];
    });
    this.categories1.forEach(element => {
      element.counter = data.counts[element.category];
    });
    this.priorityDatasets = [
      {
        data: [
          this.categories1[1].counter,
          this.categories1[3].counter,
          this.categories1[4].counter,
          this.categories1[5].counter,
          this.categories1[0].counter,
          this.categories1[2].counter
        ]
      },
    ];
    this.categoryDatasets = [
      {
        data: [
          this.categories[0].counter,
          this.categories[1].counter,
          this.categories[2].counter,
          this.categories[3].counter
        ]
      },
    ];
    this.elements = data.events;
    this.mdbTable1.setDataSource(this.elements);
    this.elements = this.mdbTable1.getDataSource();
    this.previous = this.mdbTable1.getDataSource();
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();

  }
}
