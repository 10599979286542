import {
  TokenService
} from './token.service';
import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JarwisService {
  private baseUrl = 'https://str.hed.gkp.pk/api';

  constructor(private http: HttpClient, private Token: TokenService) {}

  public taskId = 0;
  public userId = 0;
  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data);
  }
  createEvent(data) {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/createEvent`, data, {headers});
  }
  reassignEvent(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/reassignEvent`, data, {headers});
  }

  fillDashboard() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);

    return this.http.get(`${this.baseUrl}/fillDashboard`,  {headers});
  }
  createUser(data) {

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/createUser`, data, {headers});
  }
  checkEmail(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/checkEmail`, data, {headers});
  }
  getLoggedInUser<LoggedInUser>() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);

    return this.http.get(`${this.baseUrl}/getLoggedInUser`,  {headers});
  }
  getAllUserNames() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.get(`${this.baseUrl}/getAllUserNames`, {headers});
  }
  getAllTasks() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.get(`${this.baseUrl}/getAllTasks`, {headers});
  }
  getAllUsers() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.get(`${this.baseUrl}/getAllUsers`, {headers});
  }
  getAllNotifications() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.get(`${this.baseUrl}/getAllNotifications`, {headers});
  }
  getTask(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/getTask`, data, {headers});
  }
  getUser(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/getUser`, data, {headers});
  }
  addComment(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/addComment`, data, {headers});
  }
  submitFeedback(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/submitFeedback`, data, {headers});
  }
  getTaskFeedBack(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/getTaskFeedback`, data, {headers});
  }
  updateDueDate(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/updateDueDate`, data, {headers});
  }
  completeTask(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('X-Authorization', `Bearer ${this.Token.get()}`);
    return this.http.post(`${this.baseUrl}/completeTask`, data, {headers});
  }
}
