import { TokenService } from './Services/token.service';
import { JarwisService } from './Services/jarwis.service';
import { CustomMaterialModule } from './core/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CreateEventComponent } from './create-event/create-event.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateUsersComponent } from './create-users/create-users.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskDetailsComponent, modalDialog } from './task-details/task-details.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { StarRatingModule } from 'angular-star-rating';
import { UsersComponent } from './users/users.component';
import { UserDetailsComponent } from './user-details/user-details.component';
export const someCoreModuleforRoot = StarRatingModule.forRoot(); // Without "export" build will crash

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LoginComponent,
    CreateEventComponent,
    DashboardComponent,
    NotificationsComponent,
    CreateUsersComponent,
    TasksComponent,
    TaskDetailsComponent,
    modalDialog,
    UsersComponent,
    UserDetailsComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    someCoreModuleforRoot,

    MDBBootstrapModule.forRoot(),
    CustomMaterialModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,

    ReactiveFormsModule,
    SnotifyModule
  ],
  providers: [
    JarwisService,
    TokenService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ],
  entryComponents: [modalDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }
