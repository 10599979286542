import { TokenService } from './token.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate{
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  boolean |
  Observable<boolean> |
  Promise<boolean> {
    if (this.Token.loggedIn()) {
      // all ok, proceed navigation to routed component
      return true;
    }
    else {
      // start a new navigation to redirect to login page
      this.router.navigate(['/']);

      // abort current navigation
      return false;
    }
  }


  constructor(private Token: TokenService,private router: Router) { }
}
