import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() { }
  private iss = {
    api: 'localhost'
  };
  handle(token){
    this.set(token);
  }
  set(token) {
    localStorage.setItem('token', token);
  }
  get(){
    return localStorage.getItem('token');
  }
  remove(){
    localStorage.removeItem('token');
  }
  isValid(){
    const token = this.get();
    if(token){
      const payload = this.payload(token);
      if(payload){
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true: false;
      }
    }
    return false;
  }
  payload(token){
    const payload =  token.split('iss.')[1];
    return this.decode(payload);
  }
  decode(payload){
    if(payload){
      payload = payload.replace(/\s/g, '');
      return JSON.parse(escape(window.atob(payload)));
    }

  }
  loggedIn() {
    if(this.get()){
      return true;
    }
    return false;
    // return this.isValid();
  }
}
